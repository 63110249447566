import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import filters from "./filters/filters";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/reset.css";
import "@/assets/style/element-ui.scss";
import "@/assets/style/global.scss";
// import animate from "animate.css";
Vue.use(ElementUI);
// Vue.use(animate);

// filter里面的方法 同时挂载到vue实例 两种使用方式
Vue.prototype.$filters = filters;
for (const key in filters) {
  Vue.filter(key, filters[key]);
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
