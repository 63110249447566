import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
import demo2Store from "./demoStore/demo2Store";
import appStore from "./app/app";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [persistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    demo2Store,
    appStore,
  },
});
