import Api from "@/api/api.js";
export default [
  {
    path: "/index/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/layout/HomePage.vue"),
  },
  {
    path: "/index/company",
    name: "company",
    component: () =>
      import(
        /* webpackChunkName: "company" */ "@/views/layout/CompanyPage.vue"
      ),
  },
  {
    path: "/index/product",
    name: "product",
    component: () =>
      import(
        /* webpackChunkName: "product" */ "@/views/layout/ProductPage.vue"
      ),
  },
  {
    path: "/index/product2",
    name: "product2",
    component: () =>
      import(
        /* webpackChunkName: "product2" */ "@/views/layout/ProductPage2.vue"
      ),
  },
  {
    path: "/index/product/detail/:id",
    name: "productDetail",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/layout/ProductDetailPage.vue"
      ),
  },
  {
    path: "/index/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/layout/NewsPage.vue"),
  },
  {
    path: "/index/news/detail/:id",
    name: "newsDetail",
    component: () =>
      import(
        /* webpackChunkName: "newsDetail" */ "@/views/layout/NewsDetailPage.vue"
      ),
    beforeEnter: (to, from, next) => {
      Api.setNewsViewCounts(to.params.id).then();
      next();
    },
  },
  {
    path: "/index/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "@/views/layout/ContactPage.vue"
      ),
  },
];
