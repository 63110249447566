export default {
  namespaced: true,

  state: {
    number: 1,

    currentMenuPath: "/index",
  },

  getters: {
    getCurrentMenuPath(state) {
      return state.currentMenuPath;
    },
  },

  mutations: {
    updateNumber(state) {
      state.number++;
    },

    updateCurrentMenuPath(state, data) {
      state.currentMenuPath = data;
    },
  },

  actions: {
    updateNumber({ commit }) {
      commit("updateNumber");
    },

    dispatchMenuPath({ commit }, data) {
      setTimeout(() => {
        commit("updateCurrentMenuPath", data);
      }, 200);
    },
  },
};
