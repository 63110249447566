import { Message } from "element-ui";
import axios from "axios";
import store from "../store";
// import router from '../router'

axios.defaults.baseURL = "http://beauty-everyday.jp:8000/";
axios.defaults.timeout = 30000;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    const token = store.getters["appStore/getToken"];
    if (token) {
      config.headers["XXXXXXXXXX"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data);
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          Message.error("401，token异常，请重新登录");
          break;
        case 500:
          Message.error("500，服务器错误");
          break;
        default:
          Message.error(error.response.status + "，系统错误");
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
