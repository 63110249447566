import Vue from "vue";
import VueRouter from "vue-router";
import demoRoutes from "./demo/index";
import layoutRoutes from "./layout/index";
import adminRoutes from "./admin/index";
import storage from "../utils/storage";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    redirect: "/index/home",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/layout/IndexPage.vue"),
    children: [...layoutRoutes],
  },
  {
    path: "/admin",
    redirect: "/admin/productManage",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/IndexPage.vue"),
    children: [...adminRoutes],
  },
  {
    path: "/login",
    name: "login",
    title: "登录页面",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginPage.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () =>
      import(/* webpackChunkName: "demo" */ "@/views/demo/DemoIndex.vue"),
    children: [...demoRoutes],
  },
  {
    path: "*",
    name: "404",
    title: "空页面",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path.indexOf("/admin") === 0) {
    const userPwd = storage.getItem("userPwd");
    if (!userPwd) {
      alert("请登录");
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
