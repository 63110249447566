export default [
  {
    path: "/admin/productManage",
    name: "productManage",
    component: () =>
      import(
        /* webpackChunkName: "productManage" */ "@/views/admin/ProductManage.vue"
      ),
  },
  {
    path: "/admin/productManage3c",
    name: "productManage3c",
    component: () =>
      import(
        /* webpackChunkName: "productManage3c" */ "@/views/admin/ProductManage3c.vue"
      ),
  },
  {
    path: "/admin/newsManage",
    name: "newsManage",
    component: () =>
      import(
        /* webpackChunkName: "newsManage" */ "@/views/admin/NewsManage.vue"
      ),
  },
  {
    path: "/admin/contactManage",
    name: "contactManage",
    component: () =>
      import(
        /* webpackChunkName: "contactManage" */ "@/views/admin/ContactManage.vue"
      ),
  },
];
