export default {
  namespaced: true,

  state: {
    demo2List: [],
  },

  getters: {
    getDemo2List(state) {
      return state.demo2List;
    },
  },

  mutations: {
    updateDemo2List(state, data) {
      state.demo2List.push(data);
    },
  },

  actions: {},
};
