import Axios from "@/axios/index";

const Api = {
  // ========== 产品管理 ==========
  // 产品-列表
  getProductList(params) {
    return Axios({
      url: "/product/manage/list",
      method: "get",
      params,
    });
  },
  // 产品-详情
  productDetail(id) {
    return Axios({
      url: `/product/manage/detail/${id}`,
      method: "get",
    });
  },
  // 产品-新增
  addProduct(data) {
    return Axios({
      url: "/product/manage/add",
      method: "post",
      data,
    });
  },
  // 产品-编辑
  editProduct(data) {
    return Axios({
      url: "/product/manage/update",
      method: "post",
      data,
    });
  },
  // 产品-删除
  deleteProduct(data) {
    return Axios({
      url: "/product/manage/delete",
      method: "post",
      data,
    });
  },
  // 产品-发布、取消发布
  publishProduct(data) {
    return Axios({
      url: "/product/manage/publish",
      method: "post",
      data,
    });
  },
  // 产品-封面图-删除
  deleteProductCover(params) {
    return Axios({
      url: "/product/manage/delete/image",
      method: "get",
      params,
    });
  },
  // 产品-详情图-删除
  deleteProductPicture(params) {
    return Axios({
      url: "/file/delete",
      method: "get",
      params,
    });
  },

  // ========== 新闻管理 ==========
  getNewList(params) {
    return Axios({
      url: "/news/manage/list",
      method: "get",
      params,
    });
  },
  getNewDetail(id) {
    return Axios({
      url: `/news/manage/detail/${id}`,
      method: "get",
    });
  },
  addNews(data) {
    return Axios({
      url: "/news/manage/add",
      method: "post",
      data,
    });
  },
  editNews(data) {
    return Axios({
      url: "/news/manage/update",
      method: "post",
      data,
    });
  },
  deleteNews(data) {
    return Axios({
      url: "/news/manage/delete",
      method: "post",
      data,
    });
  },
  publishNews(data) {
    return Axios({
      url: "/news/manage/publish",
      method: "post",
      data,
    });
  },
  // 封面图-删除
  deleteNewsCover(params) {
    return Axios({
      url: "/news/manage/delete/image",
      method: "get",
      params,
    });
  },
  // 详情图-删除
  deleteNewsPicture(params) {
    return Axios({
      url: "/file/delete",
      method: "get",
      params,
    });
  },
  //
  getNewsViewCounts(id) {
    return Axios({
      url: `/news/manage/getCounts/${id}`,
      method: "get",
    });
  },
  //
  setNewsViewCounts(id) {
    return Axios({
      url: `/news/manage/counts/${id}`,
      method: "post",
    });
  },

  // ========== 咨询 ==========
  setContactForm(data) {
    return Axios({
      url: "/contact/manage/add",
      method: "post",
      data,
    });
  },
  getContactList(params) {
    return Axios({
      url: "/contact/manage/list",
      method: "get",
      params,
    });
  },
  contactDetail(id) {
    return Axios({
      url: `/contact/manage/detail/${id}`,
      method: "get",
    });
  },

  // queryGet(params) {
  //   return Axios({
  //     url: "",
  //     method: "get",
  //     params,
  //   });
  // },

  // queryPost(data) {
  //   return Axios({
  //     url: "",
  //     method: "post",
  //     data,
  //   });
  // },
};
export default Api;

// 调用
// import Api from '@/api/api.js'
// Api.queryGet({a:'123'}).then(res=>{
//   //do something
// })
// 或使用 async await语法糖 也可配合解构赋值

// 写法2
// export const queryGet = () => { }
// export const queryPost = () => { }
// 调用2
// import { queryGet,queryPost } from '@/api/api.js'
// queryGet({ a: '123' }).then((res) => {
//   //do something
// })
